import { Controller } from "stimulus" 

export default class extends Controller {
  static targets = ['status', 'container']

  initialize() {
    this.activeStatusClasses = (this.data.get('activeStatus') || 'active').split(' ')
    this.selectStatus()
    
    if(localStorage.scrollPosition) {
      this.containerTarget.scrollLeft = localStorage.getItem("scrollPosition")
   }
  }

  change() {
    this.index = this.statusTargets.indexOf(event.currentTarget)
    localStorage.clear()
    this.scrollPosition = this.containerTarget.scrollLeft
    localStorage.setItem("scrollPosition", this.scrollPosition)
  }

  selectStatus() {
    this.statusTargets.forEach((status, index) => {

      if (index === this.index) {
        status.classList.add(...this.activeStatusClasses)
        status.querySelector(".label").classList.remove("text-gray-700")
        status.querySelector(".count").classList.remove("text-gray-500")
        status.querySelector(".label").classList.add("text-gray-900")
        status.querySelector(".count").classList.add("text-gray-700")
      } else {
        status.classList.remove(...this.activeStatusClasses)
        status.querySelector(".label").classList.remove("text-gray-900")
        status.querySelector(".count").classList.remove("text-gray-700")
        status.querySelector(".label").classList.add("text-gray-700")
        status.querySelector(".count").classList.add("text-gray-500")
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', value)
    this.selectStatus()
  }
}