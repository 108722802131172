import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "detailBox", "editForm", "editButton" ]

  open(e) {
    e.preventDefault()
    this.detailBoxTarget.classList.add("hidden")
    this.editFormTarget.classList.remove("hidden")
    this.editButtonTarget.classList.add("opacity-50")
  }

  close(e) {
    e.preventDefault();
    this.editFormTarget.classList.add("hidden");
    this.detailBoxTarget.classList.remove("hidden")
    this.editButtonTarget.classList.remove("opacity-50")
  }
}
