import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "message"]

  connect() {
    this.toast()
  }

  toast() {
    if(document.getElementById("add-button") !== null) {
      setTimeout(() => this.fadeIn(), 0.25*1000)
    } else {
      if(this.hasMessageTarget){
        setTimeout(() => this.fadeIn(), 1)
      }
    }
    if(this.hasMessageTarget && this.hasContainerTarget) {
      setTimeout(() => this.fadeOut(), 3*1000)
      setTimeout(() => this.removeElement(), 4.5*1000)
    }
  }

  fadeIn() {
    this.messageTarget.classList.remove("opacity-0")
    this.messageTarget.classList.add("transition-opacity", "ease-in-out", "duration-300", "opacity-100")
  }

  fadeOut() {
    this.messageTarget.classList.remove("duration-300", "opacity-100")
    this.messageTarget.classList.add("duration-1000", "opacity-0")
  }
  
  removeElement() {
    this.containerTarget.remove()
  }
}