global.Book = class {
    constructor () {
        this.authors = [];
        this.image = null;
        this.publish_date = null;
        this.title = null;
        this.purchaseUrls = {};
        this.gId = null;
    }
};
