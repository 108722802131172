import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'content', 'expandLink', 'collapseLink', 'resize' ];

  connect() {
    if (!this.contentTarget.innerHTML || this.contentTarget.innerHTML.trim() === "")
    {
      this.contentTarget.innerHTML = "Description not available."
      this.expandLinkTarget.classList.add("hidden");
      return;
    }
    else {
      if (typeof book_resize_observer !== 'undefined') {
        book_resize_observer.observe (this.contentTarget);
      }
    }   
  };

  disconnect() {
    if (typeof book_resize_observer !== 'undefined') {
      book_resize_observer.unobserve (this.contentTarget);
    }
  }

  resize(event) {
    if (this.contentTarget.scrollHeight <= this.contentTarget.clientHeight) {
      this.expandLinkTarget.classList.add("hidden");
    }
    else {
      this.expandLinkTarget.classList.remove("hidden");
    }
  }

  expandContent(event) {
    event.preventDefault()
    this.contentTarget.classList.remove("h-48")
    this.expandLinkTarget.classList.add("hidden")
    this.collapseLinkTarget.classList.remove("hidden")
  }

  collapseContent(event) {
    event.preventDefault()
    this.contentTarget.classList.add("h-48")
    this.expandLinkTarget.classList.remove("hidden")
    this.collapseLinkTarget.classList.add("hidden")
  }
}
