// attach to a search box
// attach to an output div
// search google book search using the query from the search box
    // https://www.googleapis.com/books/v1/volumes?q=search+terms
// output the results to the output div

global.generateAmazonBuy = function (item) {

    //
    var url = "https://www.amazon.com/s?i=stripbooks&unfiltered=1&ref=sr_adv_b&s=relevanceexprank&Adv-Srch-Books-Submit.x=27&Adv-Srch-Books-Submit.y=7&rh="

    if (item.volumeInfo.authors && item.volumeInfo.authors.length > 0) {
        var a = item.volumeInfo.authors[0];

        var spaceIndex = a.lastIndexOf(' ');

        if (!spaceIndex) {
            spaceIndex = 0;
        }

        var author = a.substring(spaceIndex);
        url += "p_27" + encodeURIComponent(":" + author);
    }

    if (item.volumeInfo.title && item.volumeInfo.title.length > 0) {

        if (url.indexOf ("p_27" > 0)) {
            url += encodeURIComponent(",");
        }

        url += "p_28" + encodeURIComponent(":" + item.volumeInfo.title);
    }

    if (item.volumeInfo.publishedDate) {
        var pdate = item.volumeInfo.publishedDate;

        //just want the year
        var index = pdate.indexOf('-');

        //if the date is more than the year (has - )
        if (index !== -1) {
            pdate = pdate.substr(0, index);
        }

       url +=  "&field-dateop=During&field-dateyear=" + pdate

        // convert single quotes to url encoded values.  Javascript method skips them
        // not converting them will cause issues if an anchor is used href='<url>'
        url = url.replace("'", "%27")

    }

    return url;
};

global.googleBookSearch = function (options)
{
    var setOptionDefaults = function (options) {
        if (!options.startIndex) {
            options.startIndex = 0
        }

        if (!options.maxResults) {
            options.maxResults = 10;
        }
    };

    var processGoogleReponse = function(request, msg, options) {

        if (request.target.readyState === XMLHttpRequest.DONE) {
            if (request.target.status === 200) {
                if (options.responseMethod) {

                    options.responseMethod(generateGoogleBooks(request.target.response));
                }
            }

            else
            {
                console.log ("non 200 response" + request + " " + msg);
            }
        }
    };

    var generateGoogleBooks = function (jsonString) {
        books = [];

        var rJson = JSON.parse(jsonString);

        if (rJson.totalItems > 0)
        {
            rJson.items.forEach(item => {
                b = new Book();
                b.gId = item.id;

                if (item.volumeInfo.imageLinks) {
                    if (item.volumeInfo.imageLinks.thumbnail)
                        b.image = item.volumeInfo.imageLinks.thumbnail;
                    else if (item.volumeInfo.imageLinks.smallThumbnail)
                        b.image = item.volumeInfo.imageLinks.smallThumbnail;
                }

                if (b.image) {
                    b.image = b.image.replace("edge=curl","");
                    b.image = b.image.replace("http","https");
                }

                b.title = item.volumeInfo.title;
                b.subtitle = item.volumeInfo.subtitle;
                b.publish_date = item.volumeInfo.publishedDate;

                if (item.volumeInfo.authors) {
                    item.volumeInfo.authors.forEach(author => {
                        b.authors.push (author);
                    });
                }

                if (item.saleInfo && item.saleInfo.buyLink) {
                    b.purchaseUrls.google = item.saleInfo.buyLink;
                }

                b.purchaseUrls.amazon = generateAmazonBuy(item);

                books.push (b);
            });
        }

        return books;
    };

    var searchButtonClick = function() {
        var boxValue = encodeURIComponent(searchbox.value.trim());

        // don't perform search unless there is a value
        if (boxValue === null || boxValue.length ===0) {
            return;
        }

        var googleUrl = "https://www.googleapis.com/books/v1/volumes?q=" + encodeURIComponent(searchbox.value)

        var ajaxRequest = new XMLHttpRequest();
        ajaxRequest.open('GET', googleUrl, true);
        ajaxRequest.onreadystatechange = function(x,m) {processGoogleReponse(x,m,options)};
        ajaxRequest.send();

    }

    var searchbox = document.getElementById(options.searchBox);
    var searchbutton = document.getElementById(options.searchButton);

    setOptionDefaults(options);

    if (options.processNow)
    {
        searchButtonClick();
    }

    if (searchbutton) {
       searchButton.addEventListener("click", searchButtonClick);
    }
};


