import Flatpickr from 'stimulus-flatpickr'
import "flatpickr/dist/themes/light.css";

export default class extends Flatpickr {
    // connect() {
    //     console.log ("flat picker launcehd");
    //     super.connect();
    // }

    // open (event) {
 
    //     /* when in a modal the drop down does not know about scoll position change
    //     * reposition the dropdown as needed */
    //     const mutation_config  = {attributes: true, childList: false, subtree: false };

    //     if (!this.flatpickr_observer) {
    //         const flatpickr_mutationObserver = new MutationObserver(e => {
    //             console.log ("opening");
    //             let rects = this.fp._input.getClientRects()[0];
    //             let t = this.calendarContainerTarget;
    //             let newTop = (rects.y + rects.height - 1 + parseInt(window.scrollY | window.modalPreviousScrollPosition)) + "px";
    //             let oldTop = t.style.top;

    //         if(newTop != oldTop) {
    //             t.style.top = newTop;
    //         }
    //     })

    //     this.flatpickr_observer = flatpickr_mutationObserver;
    //     }

    //     this.flatpickr_observer.observe(this.calendarContainerTarget, mutation_config);
    // }

    // close() {
    //     if (this.flatpickr_observer) {
    //         this.flatpickr_observer.disconnect();
    //       }
    //     console.log ("close");
    //     console.log (this);
    // }
}