import { Controller } from "stimulus"
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = ["input", "list", "item", "deleteButton" ]

  render() {

    const tagify = new Tagify(this.inputTarget, {
      whitelist: [],
      placeholder: "Add a source...",
      dropdown: {
        enabled: 2,
        maxItems: 5,
        classname: "customSourceList",
      },
      editTags: false,
    })

    this.tagify = tagify

    var controller

    const that = this

    fetchUserBookSources()
    
    tagify.on("input", (event) => {
      var value = event.detail.value;
      tagify.settings.whitelist.length = 0; // reset the whitelist

      // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
      controller && controller.abort();
      controller = new AbortController();

      // show loading animation and hide the suggestions dropdown
      tagify.loading(false).dropdown.hide.call(tagify)
      const url = new URL(this.data.get("searchUrl") + "?q=")
      fetch(url + value, {signal:controller.signal})
        .then(RES => RES.json())
        .then(function(whitelist){
          // update inwhitelist Array in-place
          tagify.settings.whitelist.splice(0, whitelist.length, ...whitelist)
          tagify.loading(false).dropdown.show.call(tagify, value); // render the suggestions dropdown
        })
    })

    function fetchUserBookSources() {
      const url = new URL(that.data.get("indexUrl") + "?ub_id=")
      var value = that.data.get("ubId")
  
      fetch(url + value)
        .then(response => response.json())
        .then(function(sources) {
          let ub_sources = sources.map(s => s.name)
          if (ub_sources.length >= 1) {
            tagify.addTags(ub_sources)
          }
          else {
            return
          }
        })
    }
  }

  connect() {
    this.render()
  }

  disconnect() {
    if (this.tagify !== "undefined")
    {
      this.tagify.destroy();
    }
  }
}