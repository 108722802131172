import { Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "button", "box", "clearButton" ]

  connect() {
    this.toggleClearButton()
    this.moveCursorToEnd()
  }
  
  submitWithKeyboard(event) {
    if (event.keyCode === 13) {
      event.preventDefault()

      this.buttonTarget.click()
    }
  }

  toggleClearButton() {
    if(this.boxTarget.value.length > 0) {
      this.clearButtonTarget.classList.remove("hidden")
    } else {
      this.clearButtonTarget.classList.add("hidden")
    }
  }

  clearSearchBox() {
    this.boxTarget.value = ""
    this.toggleClearButton()
    this.boxTarget.focus()
  }

  moveCursorToEnd() {
    var el = this.boxTarget
    el.focus()
    if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != "undefined") {           
        var range = el.createTextRange();
        range.collapse(false);
        range.select();
    }
  }
}