import { Controller } from "stimulus" 

export default class extends Controller {
  static targets = ['status', 'container']

  initialize() {
    this.activeStatusClasses = (this.data.get('activeStatus') || 'active').split(' ')
    this.selectStatus()
   }

  selectStatus() {
    this.statusTargets.forEach((status, index) => {
      if (index === this.index) {
        status.classList.add(...this.activeStatusClasses)
        status.classList.add("text-white")
      } else {
        status.classList.remove(...this.activeStatusClasses)
        status.classList.remove("text-white")
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', value)
    this.selectStatus()
  }
}